import TIM from 'tim-js-sdk';
import _ from 'lodash'
import { getDefaultGiftList } from './system';

import { getM3u8Url, getArchiveM3u8Url, getLiveListHot, getLiveListHotRecommend, getLiveInfo, getLiveListNew, getLiveListEventShowing, getLiveListEventToShow, getLiveListEventShowed, getLiveListEventShowingAndToshow, getLiveTarget, getLiveTargetRanking, getLiveSyncInfo, getLiveHistoryComments, getLiveHistoryCommentsLimit, getLiveHistoryCommentsLimitPages, getLiveListEventRank, getLiveClubfun, getLiveListClubFunShowingIndexPage, getLiveListClubFunShowedIndexPage, getLivePayBuyInfo } from '@/api/live';

//1.vue導入
import Vue from 'vue';
//2.vue初期化
let v = new Vue();


const getDefaultState = () => {
  return {
    // config
    SDKAppID: 1400466077,

    // instances
    insTIM: undefined,
    insHLS: undefined,

    // live list(top page)
    liveList: {
      data:[],
      meta:{
        pagination: {
          total: 0,
          count: 0,
          per_page: 0,
          current_page: 0,
          total_pages: 0,
          links: {
            next: ""
          }
        }
      }
    },
    liveListRecommend: [],
    liveListEvent: {
      showing: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      },
      toshow: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      },
      showed: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      },
      showingAndToshow: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      }
    },
    liveListClubfun: {
      showing: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      },
      showed: {
        data:[],
        meta:{
          pagination: {
            total: 0,
            count: 0,
            per_page: 0,
            current_page: 0,
            total_pages: 0,
            links: {
              next: ""
            }
          }
        }
      }
    },
    

    // live info from server
    info: {
      duration: undefined,
      ended_at: undefined,
      expire_time: undefined,
      gift_amount: undefined,
      id: undefined,
      liveable_id: undefined,
      liveable_type: undefined,
      message_group: undefined,
      point: undefined,
      profile_id: undefined,
      public_time: undefined,
      status:undefined,
      room_info: {
        banner_store_path: undefined,
        name: undefined,
        twitter_text: undefined,
      },
      profile: {
        account_id: undefined,
        account_name: undefined,
        attribute: undefined,
        avatar_store_path: undefined,
        background_store_path: undefined,
        birthday: undefined,
        created_at: undefined,
        id: undefined,
        introduction: undefined,
        profileable_id: undefined,
        profileable_type: undefined,
        sns_infos: undefined,
        updated_at: undefined,
      },
      sig: undefined,
      started_at: undefined,
      stream_name: undefined,
      streamer_id: undefined,
      streamer_info_id: undefined,
      viewer_count: undefined,
      target: {
        id: undefined,
        streamer_id: undefined,
        name: undefined,
        target_point: undefined,
        current_point: undefined,
        content: undefined,
        banner_store_path: undefined,
        stage: undefined,
        updated_at: undefined,

        percentString: '0',
        ranking: [],
        otherRanking: [],
      },
    },

    // live comment list
    commentList: [],
    once_send_gift_profile_ids: [],
    // live attribute info
    liveAttributeInfo:{
      fire_time: undefined,
      ice_time: undefined,
      wind_time: undefined,
      earth_time: undefined,
      phoenix_time: undefined,
      dragon_time: undefined,
      pegasus_time: undefined,
      white_tiger_time: undefined,
      booster_type: {
        description: undefined,
        key: undefined,
        value: undefined,
      },
      booster_time: undefined,
      all_gift_booster_time: undefined,
    },

    liveEventRank: 0,
    liveClubfun: {
      id: undefined,
      streamer_id: undefined,
      title: undefined,
      banner_store_path: undefined,
      describe: undefined,
      "coin_infos_1-17-1_gold_price": undefined,
      "coin_infos_1-17-2_gold_price": undefined,
      "coin_infos_1-17-3_gold_price": undefined,
      "coin_infos_1-17-4_gold_price": undefined,
      "coin_infos_1-17-5_gold_price": undefined,
      "coin_infos_1-17-1_cash_price": undefined,
      "coin_infos_1-17-2_cash_price": undefined,
      "coin_infos_1-17-3_cash_price": undefined,
      "coin_infos_1-17-4_cash_price": undefined,
      "coin_infos_1-17-5_cash_price": undefined,
      "coin_infos_1-17-1_target_amount": undefined,
      "coin_infos_1-17-2_target_amount": undefined,
      "coin_infos_1-17-3_target_amount": undefined,
      "coin_infos_1-17-4_target_amount": undefined,
      "coin_infos_1-17-5_target_amount": undefined,
      "current_coin_amount_1-17-1": undefined,
      "current_coin_amount_1-17-2": undefined,
      "current_coin_amount_1-17-3": undefined,
      "current_coin_amount_1-17-4": undefined,
      "current_coin_amount_1-17-5": undefined,
      target_cash: undefined,
      current_cash: undefined,
      achievement_condition: undefined,
      recruitment_method: undefined,
      start_time: undefined,
      end_time: undefined,
      public_time: undefined,
      twitter_text: undefined,
      claim: undefined,
      result: undefined,
      is_posted: undefined,
    },

    liveClubfunProcess: 0,
    current_percentage:{
      ones:0,
      tens:0,
      hundreds:0,
      thousands:0
    },

    liveClubfunForUI: [
      {
        name: undefined,
        cash_price: undefined,
        gold_price: undefined,
        numList: [],
        icon: undefined,
        send_post: false,
        select_box_a: false,
        select_box_b: false,
        select_box_c: false,
        options_a: undefined,
        options_b: undefined,
        options_c: undefined,
      },
      {
        name: undefined,
        cash_price: undefined,
        gold_price: undefined,
        numList: [],
        icon: undefined,
        send_post: false,
        select_box_a: false,
        select_box_b: false,
        select_box_c: false,
        options_a: undefined,
        options_b: undefined,
        options_c: undefined,
      },
      {
        name: undefined,
        cash_price: undefined,
        gold_price: undefined,
        numList: [],
        icon: undefined,
        send_post: false,
        select_box_a: false,
        select_box_b: undefined,
        select_box_c: undefined,
        options_a: undefined,
        options_b: undefined,
        options_c: undefined,
      },
      {
        name: undefined,
        cash_price: undefined,
        gold_price: undefined,
        numList: [],
        icon: undefined,
        send_post: false,
        select_box_a: false,
        select_box_b: undefined,
        select_box_c: undefined,
        options_a: undefined,
        options_b: undefined,
        options_c: undefined,
      },
      {
        name: undefined,
        cash_price: undefined,
        gold_price: undefined,
        numList: [],
        icon: undefined,
        send_post: false,
        select_box_a: false,
        select_box_b: undefined,
        select_box_c: undefined,
        options_a: undefined,
        options_b: undefined,
        options_c: undefined,
      },
    ],

    ticket: {
      id: undefined,
      liveable_id: undefined,
      streamer_id: undefined,
      title: undefined,
      banner_store_path: undefined,
      price: undefined,
      describe: undefined,
      start_time: undefined,
      end_time: undefined,
      public_time: undefined,
      twitter_text: undefined,
      profile: undefined,
      profile_id: undefined,
      profile_account_id: undefined,
      profile_account_name: undefined,
      profile_introduction: undefined,
      profile_birthday: undefined,
      profile_avatar_store_path: undefined,
      profile_background_store_path: undefined,
      profile_attribute: undefined,
      profile_sns_infos: undefined,
      profile_profileable_type: undefined,
      profile_profileable_id: undefined,
      profile_profileable: undefined,
    },

    // live status
    liveURL: '',
    onlineCount: 0,
    tsPlus: undefined,
    tsLastExpire: -999999,
    is_first: false,

    //  live meta info
    meta: {
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 0,
        total_pages: 0,
        links: undefined
      },
      viewer_count_list: undefined
    }
  }
}

// eventの中身から配信者のランキングを整形する
const filterEventRanking = (events) => {
  let retData = [];
  events.data.data.forEach((event) => {
    event.streamers = {}
    let streamer_list = [];
    const { streamer } = event;
    if ((streamer || []).length == 0) {
      event.streamers.data = {}
    } else {
      streamer.data.forEach((s) => {
        if( s.rank_point > 0 ) {
          streamer_list.push({streamer_info: s.streamer_info});
        }
      })
      event.streamers.data = streamer_list
    }
    retData.push(event);
  });
  return retData;
}

const getTargetPercentage = (current,total)=>{
  const num = Math.floor(current / total * 100);
  return `${num > 100 ? 100 : num}`
}
const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    const initState = getDefaultState();
    const keys = Object.keys(initState);
    for(let i = 0; i !== keys.length; i++) {
      if (_.isObject(initState[keys[i]])) {
        state[keys[i]] = Object.assign({}, initState[keys[i]]);
      } else {
        state[keys[i]] = initState[keys[i]];
      }
    }
  },

  // instance
  SET_INS_TIM: (state, ins) => {
    state.insTIM = ins
  },
  SET_INS_HLS: (state, ins) => {
    state.insHLS = ins
  },

  // live list
  SET_LIVE_LIST: (state, value) => {
    state.liveList = value;
  },

  // live count list
  SET_VIEWER_COUNT_LIST: (state, value) => {
    state.liveList.forEach(element => {
      element.viewer_count = value[element.id];
    });
  },

  // live list
  SET_LIVE_LIST_RECOMMEND: (state, value) => {
    state.liveListRecommend = value;
  },

  // init comment list
  INIT_COMMENT_LIST: (state) => {
    state.commentList = []
  },

  // set comment list
  SET_COMMENT_LIST: (state, value) => {
    state.commentList = value;
  },
  // comment
  ADD_TO_COMMENT_LIST: (state, oneComment) => {
    state.commentList.push(oneComment)
  },

  SET_GIFT_SEND_LIST: (state, profileIdList) => {
    state.once_send_gift_profile_ids = profileIdList;
  },
  // comment
  ADD_TO_GIFT_SEND_LIST: (state, profileId) => {
    state.once_send_gift_profile_ids.push(profileId)
  },

  SET_LIVE_EVENT_RANK: (state, value) => {
    state.liveEventRank = value;
  },

  // comment
  UPDATE_LIVE_ATTR_INFO: (state, liveAttributeInfo) => {
    state.liveAttributeInfo = liveAttributeInfo;
  },

  // live info
  SET_LIVE_INFO: (state, value) => {
    state.info.duration = value.duration;
    state.info.ended_at = value.ended_at;
    state.info.expire_time = value.expire_time;
    state.info.gift_amount = value.gift_amount;
    state.info.id = value.id;
    state.info.liveable_id = value.liveable_id;
    state.info.liveable_type = value.liveable_type;
    state.info.message_group = value.message_group;
    state.info.point = value.point;
    state.info.profile_id = value.profile_id;
    state.info.public_time = value.public_time;
    state.info.status = value.status;
    state.info.is_buy_pay = value.is_buy_pay;
    state.info.pay_archive_expired_at = value.pay_archive_expired_at;

    state.info.room_info.banner_store_path = value.room_info && value.room_info.banner_store_path? value.room_info.banner_store_path:"";
    state.info.room_info.name = value.room_info && value.room_info.name? value.room_info.name:"";
    state.info.room_info.twitter_text = value.room_info && value.room_info.twitter_text? value.room_info.twitter_text:"";

    state.info.profile.account_id = value.profile.account_id;
    state.info.profile.account_name = value.profile.account_name;
    state.info.profile.attribute = value.profile.attribute;
    state.info.profile.avatar_store_path = value.profile.avatar_store_path;
    state.info.profile.background_store_path = value.profile.background_store_path;
    state.info.profile.birthday = value.profile.birthday;
    state.info.profile.created_at = value.profile.created_at;
    state.info.profile.id = value.profile.id;
    state.info.profile.introduction = value.profile.introduction;
    state.info.profile.profileable_id = value.profile.profileable_id;
    state.info.profile.profileable_type = value.profile.profileable_type;
    state.info.profile.sns_infos = value.profile.sns_infos;
    state.info.profile.updated_at = value.profile.updated_at;

    state.info.sig = value.sig;
    state.info.started_at = value.started_at;
    state.info.stream_name = value.stream_name;
    state.info.streamer_id = value.streamer_id;
    state.info.streamer_info_id = value.streamer_info_id;
    state.info.viewer_count = value.viewer_count;
    state.onlineCount = value.viewer_count;
  },

  SET_SYNC_LIVE_INFO: (state, LiveInfo)=> {
    state.info.status = LiveInfo.status;
    state.info.viewer_count = LiveInfo.viewer_count;
    state.onlineCount = LiveInfo.viewer_count;
  },
  // SET_LIVE_ID: (state, id) => {
  //   state.info.id = id;
  // },
  SET_LIVE_TARGET: (state, target) => {
    state.info.target.id = target.id
    state.info.target.streamer_id = target.streamer_id
    state.info.target.name = target.name
    state.info.target.target_point = target.target_point
    state.info.target.current_point = target.current_point
    state.info.target.content = target.content
    state.info.target.banner_store_path = target.banner_store_path
    state.info.target.stage = target.stage
    state.info.target.updated_at = target.updated_at

    state.info.target.percentString= target.percentString
    state.info.target.ranking = target.ranking
    state.info.target.otherRanking = target.otherRanking
  },

  SET_TARGET_RANKING: (state, ranking) => {
    state.info.target.ranking = ranking
  },


  SET_SYNC_LIVE_TARGET: (state, target) => {
    state.info.target.target_point = target.target_point
    state.info.target.current_point = target.current_point
    state.info.target.percentString = target.percentString
    state.info.target.ranking = target.rank
  },

  SET_LIVE_TARGET_PROGRESS: (state,param)=>{
    state.info.target.current_point = param.currentPoint
    state.info.target.percentString= param.percentString
  },

  SET_LIVE_PAY_TICKET: (state, value) => {
    state.ticket.id = value.id;
    state.ticket.liveable_id = value.liveable_id;
    state.ticket.streamer_id = value.streamer_id;
    state.ticket.title = value.title;
    state.ticket.banner_store_path = value.banner_store_path;
    state.ticket.price = value.price;
    state.ticket.describe = value.describe;
    state.ticket.start_time = value.start_time;
    state.ticket.end_time = value.end_time;
    state.ticket.public_time = value.public_time;
    state.ticket.twitter_text = value.twitter_text;
    state.ticket.profile = value.profile;
    state.ticket.profile_id = value.profile.id;
    state.ticket.profile_account_id = value.profile.account_id;
    state.ticket.profile_account_name = value.profile.account_name;
    state.ticket.profile_introduction = value.profile.introduction;
    state.ticket.profile_birthday = value.profile.birthday;
    state.ticket.profile_avatar_store_path = value.profile.avatar_store_path;
    state.ticket.profile_background_store_path = value.profile.background_store_path;
    state.ticket.profile_attribute = value.profile.attribute;
    state.ticket.profile_sns_infos = value.profile.sns_infos;
    state.ticket.profile_profileable_type = value.profile.profileable_type;
    state.ticket.profile_profileable_id = value.profile.profileable_id;
    state.ticket.profile_profileable = value.profile.profileable;
  },

  // live status
  SET_LIVE_URL: (state, url) => {
    state.liveURL = url;
  },
  SET_ONLINE_COUNT: (state, num) => {
    // state.onlineCount = num-1 == -1 ? 0 : num-1;
    state.onlineCount = num;
  },
  SET_TS_PLUS: (state, value) => {
    state.tsPlus = value;
  },
  SET_TS_LAST_EXPIRE: (state, value) => {
    state.tsLastExpire = value;
  },
  SET_IS_FIRST_TIME: (state, value) => {
    state.is_first = value;
  },
  SET_LIVE_LIST_EVENT: (state, value) => {
    state.liveListEvent = value;
  },
  SET_LIVE_LIST_EVENT_SHOWING: (state, value) => {
    value.data.forEach(element => {
      state.liveListEvent.showing.data.push(element);
    })
    state.liveListEvent.showing.meta = value.meta
  },
  SET_LIVE_LIST_EVENT_TOSHOW: (state, value) => {
    value.data.forEach(element => {
      state.liveListEvent.toshow.data.push(element);
    })
    state.liveListEvent.toshow.meta = value.meta
  },
  SET_LIVE_LIST_EVENT_SHOWED:  (state, value) => {
    value.data.forEach(element => {
      state.liveListEvent.showed.data.push(element);
    })
    state.liveListEvent.showed.meta = value.meta
  },
  SET_LIVE_LIST_EVENT_SHOWING_AND_TOSHOW: (state, value) => {
    value.data.forEach(element => {
      state.liveListEvent.showingAndToshow.data.push(element);
    })
    state.liveListEvent.showingAndToshow.meta = value.meta
  },
  SET_LIVE_LIST_CLUBFUN: (state, value) => {
    state.liveListClubfun = value;
  },
  ADD_LIVE_LIST_CLUBFUN_SHOWING: (state, value) => {
    value.data.forEach(element => {
      state.liveListClubfun.showing.data.push(element);
    })
    state.liveListClubfun.showing.meta = value.meta
  },
  ADD_LIVE_LIST_CLUBFUN_SHOWED: (state, value) => {
    value.data.forEach(element => {
      state.liveListClubfun.showed.data.push(element);
    })
    state.liveListClubfun.showed.meta = value.meta
  },
  SET_LIVE_CLUBFUN:  (state, param) => {
    const value = param.data;
    const live_item_amount = param.live_item_amount;
      state.liveClubfun.id = value.id;
      state.liveClubfun.streamer_id = value.streamer_id;
      state.liveClubfun.title = value.title;
      state.liveClubfun.banner_store_path = value.banner_store_path;
      state.liveClubfun.describe = value.describe;
      state.liveClubfun["coin_infos_1-17-1_gold_price"] = value["coin_infos_1-17-1"].gold_price
      state.liveClubfun["coin_infos_1-17-2_gold_price"] = value["coin_infos_1-17-2"].gold_price
      state.liveClubfun["coin_infos_1-17-3_gold_price"] = value["coin_infos_1-17-3"].gold_price
      state.liveClubfun["coin_infos_1-17-4_gold_price"] = value["coin_infos_1-17-4"].gold_price
      state.liveClubfun["coin_infos_1-17-5_gold_price"] = value["coin_infos_1-17-5"].gold_price
      state.liveClubfun["coin_infos_1-17-1_cash_price"] = value["coin_infos_1-17-1"].cash_price
      state.liveClubfun["coin_infos_1-17-2_cash_price"] = value["coin_infos_1-17-2"].cash_price
      state.liveClubfun["coin_infos_1-17-3_cash_price"] = value["coin_infos_1-17-3"].cash_price
      state.liveClubfun["coin_infos_1-17-4_cash_price"] = value["coin_infos_1-17-4"].cash_price
      state.liveClubfun["coin_infos_1-17-5_cash_price"] = value["coin_infos_1-17-5"].cash_price
      state.liveClubfun["coin_infos_1-17-1_target_amount"] = value["coin_infos_1-17-1"].target_amount
      state.liveClubfun["coin_infos_1-17-2_target_amount"] = value["coin_infos_1-17-2"].target_amount
      state.liveClubfun["coin_infos_1-17-3_target_amount"] = value["coin_infos_1-17-3"].target_amount
      state.liveClubfun["coin_infos_1-17-4_target_amount"] = value["coin_infos_1-17-4"].target_amount
      state.liveClubfun["coin_infos_1-17-5_target_amount"] = value["coin_infos_1-17-5"].target_amount
      state.liveClubfun["current_coin_amount_1-17-1"] = value["current_coin_amount_1-17-1"];
      state.liveClubfun["current_coin_amount_1-17-2"] = value["current_coin_amount_1-17-2"];
      state.liveClubfun["current_coin_amount_1-17-3"] = value["current_coin_amount_1-17-3"];
      state.liveClubfun["current_coin_amount_1-17-4"] = value["current_coin_amount_1-17-4"];
      state.liveClubfun["current_coin_amount_1-17-5"] = value["current_coin_amount_1-17-5"];
      state.liveClubfun.target_cash = value.target_cash;
      state.liveClubfun.current_cash = value.current_cash;
      state.liveClubfun.achievement_condition = value.achievement_condition;
      state.liveClubfun.recruitment_method = value.recruitment_method;
      state.liveClubfun.start_time = value.start_time;
      state.liveClubfun.end_time = value.end_time;
      state.liveClubfun.public_time = value.public_time;
      state.liveClubfun.twitter_text = value.twitter_text;
      state.liveClubfun.claim = value.claim;
      state.liveClubfun.result = value.result;
      state.liveClubfun.is_posted = value.is_posted;

      state.liveClubfunForUI[0].type = '1-17-1';
      // state.liveClubfunForUI[0].name = value.name;
      state.liveClubfunForUI[0].plan_name = value["coin_infos_1-17-1"].plan_name;
      state.liveClubfunForUI[0].gold_price = value["coin_infos_1-17-1"].gold_price
      state.liveClubfunForUI[0].cash_price = value["coin_infos_1-17-1"].cash_price
      state.liveClubfunForUI[0].bought_count = value["bought_coin_1-17-1"]
      state.liveClubfunForUI[0].numList = Array.apply(null, Array(live_item_amount.t1)).map(function (x, i) { return i+1; });
      state.liveClubfunForUI[0].icon = "/img/clubfun-icon1.png";
      state.liveClubfunForUI[0].send_post = value["coin_infos_1-17-1"].send_post
      state.liveClubfunForUI[1].type = '1-17-2';
      state.liveClubfunForUI[1].plan_name = value["coin_infos_1-17-2"].plan_name;
      state.liveClubfunForUI[1].gold_price = value["coin_infos_1-17-2"].gold_price
      state.liveClubfunForUI[1].cash_price = value["coin_infos_1-17-2"].cash_price
      state.liveClubfunForUI[1].bought_count = value["bought_coin_1-17-2"]
      state.liveClubfunForUI[1].numList = Array.apply(null, Array(live_item_amount.t1)).map(function (x, i) { return i+1; });
      state.liveClubfunForUI[1].icon = "/img/clubfun-icon2.png";
      state.liveClubfunForUI[1].send_post = value["coin_infos_1-17-2"].send_post
      state.liveClubfunForUI[2].type = '1-17-3';
      state.liveClubfunForUI[2].plan_name = value["coin_infos_1-17-3"].plan_name;
      state.liveClubfunForUI[2].gold_price = value["coin_infos_1-17-3"].gold_price
      state.liveClubfunForUI[2].cash_price = value["coin_infos_1-17-3"].cash_price
      state.liveClubfunForUI[2].bought_count = value["bought_coin_1-17-3"]
      state.liveClubfunForUI[2].numList = Array.apply(null, Array(live_item_amount.t1)).map(function (x, i) { return i+1; });
      state.liveClubfunForUI[2].icon = "/img/clubfun-icon3.png";
      state.liveClubfunForUI[2].send_post = value["coin_infos_1-17-3"].send_post
      state.liveClubfunForUI[3].type = '1-17-4';
      state.liveClubfunForUI[3].plan_name = value["coin_infos_1-17-4"].plan_name;
      state.liveClubfunForUI[3].gold_price = value["coin_infos_1-17-4"].gold_price
      state.liveClubfunForUI[3].cash_price = value["coin_infos_1-17-4"].cash_price
      state.liveClubfunForUI[3].bought_count = value["bought_coin_1-17-4"]
      state.liveClubfunForUI[3].numList = Array.apply(null, Array(live_item_amount.t1)).map(function (x, i) { return i+1; });
      state.liveClubfunForUI[3].icon = "/img/clubfun-icon4.png";
      state.liveClubfunForUI[3].send_post = value["coin_infos_1-17-4"].send_post
      state.liveClubfunForUI[4].type = '1-17-5';
      state.liveClubfunForUI[4].plan_name = value["coin_infos_1-17-5"].plan_name;
      state.liveClubfunForUI[4].gold_price = value["coin_infos_1-17-5"].gold_price
      state.liveClubfunForUI[4].cash_price = value["coin_infos_1-17-5"].cash_price
      state.liveClubfunForUI[4].bought_count = value["bought_coin_1-17-5"]
      state.liveClubfunForUI[4].numList = Array.apply(null, Array(live_item_amount.t1)).map(function (x, i) { return i+1; });
      state.liveClubfunForUI[4].icon = "/img/clubfun-icon5.png";
      state.liveClubfunForUI[4].send_post = value["coin_infos_1-17-5"].send_post
      state.liveClubfunForUI[0].select_box_a = value["coin_infos_1-17-1"].select_box_a
      state.liveClubfunForUI[1].select_box_a = value["coin_infos_1-17-2"].select_box_a
      state.liveClubfunForUI[2].select_box_a = value["coin_infos_1-17-3"].select_box_a
      state.liveClubfunForUI[3].select_box_a = value["coin_infos_1-17-4"].select_box_a
      state.liveClubfunForUI[4].select_box_a = value["coin_infos_1-17-5"].select_box_a
      state.liveClubfunForUI[0].select_box_b = value["coin_infos_1-17-1"].select_box_b
      state.liveClubfunForUI[1].select_box_b = value["coin_infos_1-17-2"].select_box_b
      state.liveClubfunForUI[2].select_box_b = value["coin_infos_1-17-3"].select_box_b
      state.liveClubfunForUI[3].select_box_b = value["coin_infos_1-17-4"].select_box_b
      state.liveClubfunForUI[4].select_box_b = value["coin_infos_1-17-5"].select_box_b
      state.liveClubfunForUI[0].select_box_c = value["coin_infos_1-17-1"].select_box_c
      state.liveClubfunForUI[1].select_box_c = value["coin_infos_1-17-2"].select_box_c
      state.liveClubfunForUI[2].select_box_c = value["coin_infos_1-17-3"].select_box_c
      state.liveClubfunForUI[3].select_box_c = value["coin_infos_1-17-4"].select_box_c
      state.liveClubfunForUI[4].select_box_c = value["coin_infos_1-17-5"].select_box_c
      state.liveClubfunForUI[0].options_a = value["coin_infos_1-17-1"].options_a
      state.liveClubfunForUI[1].options_a = value["coin_infos_1-17-2"].options_a
      state.liveClubfunForUI[2].options_a = value["coin_infos_1-17-3"].options_a
      state.liveClubfunForUI[3].options_a = value["coin_infos_1-17-4"].options_a
      state.liveClubfunForUI[4].options_a = value["coin_infos_1-17-5"].options_a
      state.liveClubfunForUI[0].options_b = value["coin_infos_1-17-1"].options_b
      state.liveClubfunForUI[1].options_b = value["coin_infos_1-17-2"].options_b
      state.liveClubfunForUI[2].options_b = value["coin_infos_1-17-3"].options_b
      state.liveClubfunForUI[3].options_b = value["coin_infos_1-17-4"].options_b
      state.liveClubfunForUI[4].options_b = value["coin_infos_1-17-5"].options_b
      state.liveClubfunForUI[0].options_c = value["coin_infos_1-17-1"].options_c
      state.liveClubfunForUI[1].options_c = value["coin_infos_1-17-2"].options_c
      state.liveClubfunForUI[2].options_c = value["coin_infos_1-17-3"].options_c
      state.liveClubfunForUI[3].options_c = value["coin_infos_1-17-4"].options_c
      state.liveClubfunForUI[4].options_c = value["coin_infos_1-17-5"].options_c
      state.liveClubfunProcess = value.current_percentage

  },
  SET_CURRENT_PERCENTAGE: (state, value) => {
    state.liveClubfunProcess = value.current_percentage;
    state.current_percentage.ones = value.ones
    state.current_percentage.tens = value.tens
    state.current_percentage.hundreds = value.hundreds
    state.current_percentage.thousands = value.thousands
  },
  SET_LIVE_POINT: (state, value) => {
    state.info.point = value;
  }
}

const actions = {
  async reset({ commit }) {
    commit('RESET_STATE');
  },

  // live hot list
  async syncLiveListHot({commit},{limit,page}) {
    const ret = await getLiveListHot(limit,page);
    commit('SET_LIVE_LIST', ret.data);
  },

  // live hot recommend list
  async syncLiveListHotRecommend({commit}) {
    const ret = await getLiveListHotRecommend();
    commit('SET_LIVE_LIST_RECOMMEND', ret.data.data);
  },

  // live new list
  async syncLiveListNew({commit},{limit,page}) {
    const { data } = await getLiveListNew(limit,page);
    commit('SET_LIVE_LIST', data.data);
    commit('SET_VIEWER_COUNT_LIST', data.meta.viewer_count_list);

  },
  // live event list
  async syncLiveListEvent({commit, state}, data) {
    let showing = state.liveListEvent.showing;
    let toshow = state.liveListEvent.toshow;
    let showed = state.liveListEvent.showed;
    let showingAndToshow = state.liveListEvent.showingAndToshow;

    if (data.isShowingToshowMix) {
      const ret = await getLiveListEventShowingAndToshow(data.limit, data.page);
      showingAndToshow = {
        data: filterEventRanking(ret),
        meta: ret.data.meta,
      };

    } else {
      const ret1 = await getLiveListEventShowing(data.limit, data.page);
      showing = {
        data: filterEventRanking(ret1),
        meta: ret1.data.meta,
      };

      const ret2 = await getLiveListEventToShow(data.limit, data.page);
      toshow = ret2.data;
    }

    const ret3 = await getLiveListEventShowed(data.limit, data.page);
    showed = {
      data: filterEventRanking(ret3),
      meta: ret3.data.meta,
    };

    commit('SET_LIVE_LIST_EVENT', {
      showing,
      toshow,
      showed,
      showingAndToshow,
    });
  },

  async showMoreEventShowing({commit}, data) {
    const ret = await getLiveListEventShowing(data.limit, data.page);
    let result = {
      data: filterEventRanking(ret),
      meta: ret.data.meta,
    };
    commit('SET_LIVE_LIST_EVENT_SHOWING', result);
  },
  async showMoreEventToShow({commit}, data) {
    const ret = await getLiveListEventToShow(data.limit, data.page);
    commit('SET_LIVE_LIST_EVENT_TOSHOW', ret.data,);
  },
  async showMoreEventShowed({commit}, data) {
    const ret = await getLiveListEventShowed(data.limit, data.page);
    let result = {
      data: filterEventRanking(ret),
      meta: ret.data.meta,
    };
    commit('SET_LIVE_LIST_EVENT_SHOWED', result);
  },
  async showMoreEventShowingAndToshow({commit}, data) {
    const ret = await getLiveListEventShowingAndToshow(data.limit, data.page);
    let result = {
      data: filterEventRanking(ret),
      meta: ret.data.meta,
    };
    commit('SET_LIVE_LIST_EVENT_SHOWING_AND_TOSHOW', result);
  },

  // live clubfun list
  async syncLiveListClubfun({commit}, data) {
    const ret1 = await getLiveListClubFunShowingIndexPage(data.index, data.page);
    let showing = {
      data: ret1.data.data,
      meta: ret1.data.meta
    }
    const ret2 = await getLiveListClubFunShowedIndexPage(data.index, data.page);
    let showed = {
      data: ret2.data.data,
      meta: ret2.data.meta
    }
    commit('SET_LIVE_LIST_CLUBFUN', {
      showing: showing,
      showed: showed,
    });
  },

  async showMoreClubfunShowing({commit}, data) {
    const ret = await getLiveListClubFunShowingIndexPage(data.index, data.page);
    let result = {
      data: ret.data.data,
      meta: ret.data.meta
    }
    commit('ADD_LIVE_LIST_CLUBFUN_SHOWING', result);
  },

  async showMoreClubfunShowed({commit}, data) {
    const ret = await getLiveListClubFunShowedIndexPage(data.index, data.page);
    let result = {
      data: ret.data.data,
      meta: ret.data.meta
    }
    commit('ADD_LIVE_LIST_CLUBFUN_SHOWED', result);
  },

  async syncLiveEventRank({ commit, state }) {
    const { data } = await getLiveListEventRank(state.info.liveable_id, state.info.streamer_id);
    commit('SET_LIVE_EVENT_RANK', data[0]);
  },

  // live
  async liveInit({ commit, dispatch, state }, param) {
    const idFromParam = param.id;
    const live_item_amount = param.live_item_amount;
    let liveData = {};
    try {
      const retInfo = await getLiveInfo(idFromParam);
      liveData = retInfo.data;
    } catch(e) {
      // something went wrong when try to fetch live data
      // ブロックされた場合エラーメッセージを出す
      if (e.response.data.code === 400114) {
        v.$alert("入出が拒否されました")
        .then(()=>{
          window.opener=null;
          window.open('','_self');
          window.close();
        })
      }
      return false;
    }
    try{
      if(!liveData.is_buy_pay && liveData.liveable_type === "PAY"){
        const { data } =  await getLivePayBuyInfo(liveData.liveable_id)
        data.liveable_id = liveData.liveable_id
        commit('SET_LIVE_PAY_TICKET', data);
      }
    }catch(e){
      console.log(e);
      return false;
    }

    const defaultGiftCostList = getDefaultGiftList();
    const reqTs = parseInt(new Date().valueOf());
    const ret = await getLiveHistoryComments(liveData.id, reqTs);
    ret.data.data = ret.data.data.map(v => {
      v.payload.data = v.payload.Data
      v.payload.description = v.payload.Desc
      v.payload.Ext.GIFT_ID = defaultGiftCostList[v.payload.Ext.RAW.type] ? defaultGiftCostList[v.payload.Ext.RAW.type].name : null;
      v.payload.Ext.giftIcon = defaultGiftCostList[v.payload.Ext.RAW.type] ? require("../../assets/gift-icon/" + defaultGiftCostList[v.payload.Ext.RAW.type].icon) : null;
      v.payload.extension = JSON.stringify(v.payload.Ext);
      return v
    }).reverse();
    commit('SET_COMMENT_LIST', ret.data.data);
    commit('SET_GIFT_SEND_LIST', ret.data.once_send_gift_profile_ids);
    if (idFromParam !== '' && idFromParam !== undefined) {
      commit('SET_LIVE_INFO', liveData);
      if (state.info.liveable_type === "NORMAL") {
        await dispatch('syncTarget');
      }
      if (state.info.liveable_type === "EVENT") {
        await dispatch('syncLiveEventRank');
      }
      if (state.info.liveable_type === "FUND") {
        await dispatch('syncLiveClubfun', live_item_amount);
      }
      return true;
    } else {
      return false;
    }
  },


  async archiveLiveInit({ commit,dispatch,state }, param) {
    const idFromParam = param.id;
    // const live_item_amount = param.live_item_amount;
    const limit = param.limit;
    let liveData = {};
    try {
      const retInfo = await getLiveInfo(idFromParam);
      liveData = retInfo.data;
    } catch(e) {
      // something went wrong when try to fetch live data
      console.log(e);
      return false;
    }
    try{
      if(!liveData.is_buy_pay && liveData.liveable_type === "PAY"){
        const { data } =  await getLivePayBuyInfo(liveData.liveable_id)
        data.liveable_id = liveData.liveable_id
        commit('SET_LIVE_PAY_TICKET', data);
      }
    }catch(e){
      console.log(e);
      return false;
    }

    const defaultGiftCostList = getDefaultGiftList();
    const reqTs = parseInt(new Date().valueOf());
    const ret = await getLiveHistoryCommentsLimit(liveData.id, reqTs, limit);
    let commentListArray = ret.data.data
    if (ret.data.meta.pagination.total_pages > 1) {
      for(let i = 1; i<ret.data.meta.pagination.total_pages; i++) {
        const retPages = await getLiveHistoryCommentsLimitPages(liveData.id, reqTs, limit, i+1);
        retPages.data.data.forEach(element => {
          commentListArray.push(element)
        });
      }
    }
    commentListArray = commentListArray.map(v => {
      v.payload.data = v.payload.Data
      v.payload.description = v.payload.Desc
      v.payload.Ext.GIFT_ID = defaultGiftCostList[v.payload.Ext.RAW.type] ? defaultGiftCostList[v.payload.Ext.RAW.type].name : null;
      v.payload.Ext.giftIcon = defaultGiftCostList[v.payload.Ext.RAW.type] ? require("../../assets/gift-icon/" + defaultGiftCostList[v.payload.Ext.RAW.type].icon) : null;
      v.payload.extension = JSON.stringify(v.payload.Ext);
      return v
    }).reverse();
    commit('SET_COMMENT_LIST', commentListArray);
    commit('SET_GIFT_SEND_LIST', ret.data.once_send_gift_view_ids);
    if (idFromParam !== '' && idFromParam !== undefined) {
      commit('SET_LIVE_INFO', liveData);
      if (state.info.target.id) {
        await dispatch('syncTarget');
      }
      if (state.info.liveable_type && state.info.liveable_type === "EVENT") {
        await dispatch('syncLiveEventRank');
      }
      // await dispatch('syncTarget');
      // await dispatch('syncLiveEventRank');
      // if (state.info.liveable_type === "FUND") {
      //   await dispatch('syncLiveClubfun', live_item_amount);
      // }
      return true;
    } else {
      return false;
    }
  },

  async syncLiveInfo({ commit }, id) {
    const retInfo = await getLiveInfo(id);
    commit('SET_LIVE_INFO', retInfo.data);
  },

  async syncLiveInfoForTimeUpdate ({ commit, state }) {
    const retInfo = await getLiveSyncInfo(state.info.id);
    commit('SET_SYNC_LIVE_INFO', retInfo.data);
    if(retInfo.data.target){
      retInfo.data.target.percentString = getTargetPercentage(retInfo.data.target.current_point,retInfo.data.target.target_point)
      commit('SET_SYNC_LIVE_TARGET', retInfo.data.target)
    }
    if(retInfo.data.event){
      commit('SET_LIVE_EVENT_RANK', retInfo.data.event.rank)
    }
  },

  async syncLiveTicketInfo({ commit }, liveable_id) {
    try{
      const { data } =  await getLivePayBuyInfo(liveable_id)
      data.liveable_id = liveable_id
      commit('SET_LIVE_PAY_TICKET', data);
    }catch(e){
      console.log(e);
      return false;
    }
  },

  async syncLiveClubfun({ dispatch, commit, state }, live_item_amount) {
    const { data } = await getLiveClubfun(state.info.liveable_id);
    commit('SET_LIVE_CLUBFUN', {data, live_item_amount});
    await dispatch('setCurrentPercentageInfo',state.liveClubfunProcess)
  },

  async syncTarget({ commit, state }, streamer_id) {
    if (!state.info.liveable_type || state.info.liveable_type !== "NORMAL") {
      return;
    }
    try {
      const ret = streamer_id ? await getLiveTarget(streamer_id) : await getLiveTarget(state.info.streamer_id)
      if(ret.data.id && state.info.started_at > ret.data.created_at){
        const ret2 = await getLiveTargetRanking(ret.data.id)
        ret.data.percentString = getTargetPercentage(ret.data.current_point,ret.data.target_point)
        const ranking = []
        const otherRanking = []
        if(ret2.data.length > 0) {
          ret2.data.forEach((element,index) => {
            if(index < 12){
              ranking.push(element)
            }else {
              otherRanking.push(element)
            }
          });
        }
        ret.data.ranking = ranking
        ret.data.otherRanking = otherRanking
        commit('SET_LIVE_TARGET', ret.data);
      }
    } catch(e) {
      commit('SET_LIVE_TARGET', {});
    }
  },

  async putOtherRankingUser({ commit, state }) {
    const rankingList = state.info.target.ranking
    state.info.target.otherRanking.forEach(element => {
      rankingList.push(element)
    });
    commit('SET_TARGET_RANKING', rankingList);
  },

  updateTargetPoint({commit,state},currentPoint){
    if (!state.info.liveable_type || state.info.liveable_type !== "NORMAL") {
      return;
    }
    try {
      const percentString = getTargetPercentage(currentPoint,state.info.target.target_point)
      commit('SET_LIVE_TARGET_PROGRESS',{currentPoint:currentPoint, percentString:percentString});
    } catch(e) {
      commit('SET_LIVE_TARGET_PROGRESS', 0);
    }
  },

  async syncLiveURL({commit, state, rootState}) {
    if (state.info.id === -1) {
      return;
    }
    if (state.tsPlus === undefined) {
      const result = new Date().getTime()/1000 - rootState.system.timestamp;
      commit('SET_TS_PLUS', result);
    }
    if (state.tsLastExpire - new Date().getTime()/1000 + state.tsPlus < 20) {
      const data = (await getM3u8Url(state.info.id)).data;
      commit('SET_TS_LAST_EXPIRE', data.expire_time);
      commit('SET_IS_FIRST_TIME', data.is_first);
      return commit('SET_LIVE_URL', data.url);
    }
  },

  async syncArchiveLiveURL({commit, state, rootState}) {
    if (state.info.id === -1) {
      return;
    }
    if (state.tsPlus === undefined) {
      const result = new Date().getTime()/1000 - rootState.system.timestamp;
      commit('SET_TS_PLUS', result);
    }
    if (state.tsLastExpire - new Date().getTime()/1000 + state.tsPlus < 20) {
      const data = (await getArchiveM3u8Url(state.info.id)).data;
      commit('SET_TS_LAST_EXPIRE', data.expire_time);
      return commit('SET_LIVE_URL', data.url);
    }
  },



  // tim
  async timInit({ commit, state }) {
    commit('SET_INS_TIM', TIM.create({
      SDKAppID: state.SDKAppID,
    }));

    state.insTIM.setLogLevel(6);
  },
  async timLogin({ state, rootState }, sig) {
    await state.insTIM.login({
      userID: rootState.user.module + rootState.user.id,
      userSig: sig || state.info.sig
    });
  },
  async timLogout({ state }) {
    await state.insTIM.logout();
  },
  async timJoinGroup({ state }, message_group) {
    let promise = state.insTIM.joinGroup({
      groupID: message_group || state.info.message_group,
      type: TIM.TYPES.GRP_AVCHATROOM
    });
    promise.then(function(imResponse) {
      switch (imResponse.data.status) {
        case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 管理員の同意待ち
          break;
        case TIM.TYPES.JOIN_STATUS_SUCCESS: // 成功
          // console.log(imResponse.data.group); // 申し込んだグループの資料
          break;
        case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 既にグループにいます
          break;
        default:
          break;
      }
    }).catch(function(imError){
      console.warn('joinGroup error:', imError); // グループ申し込み失敗メッセージ
    })
  },

  async timUpdateCommentList({ commit, state, dispatch }, commentOne) {
    // イベント処理
    if ( commentOne.payload.data == 'event' ){
      switch( commentOne.payload.description ){
        // ライブ情報更新
        case 'LIVE_INFO' :
          //commit('UPDATE_LIVE_ATTR_INFO', JSON.parse(commentOne.payload.extension).RAW);
          dispatch('updateLiveAttrInfo', JSON.parse(commentOne.payload.extension).RAW);
          break;
        }
      } else {
        commit('ADD_TO_COMMENT_LIST', commentOne);
        if( commentOne.payload.data === 'gift'){
          const profile_id = JSON.parse(commentOne.payload.extension).PROFILE_ID
          if(profile_id && state.once_send_gift_profile_ids.indexOf(profile_id) == -1) {
            commit('ADD_TO_GIFT_SEND_LIST', profile_id);
          }
          // if (state.info.target.id) {
          //   await dispatch('syncTarget');
          // }
          // if (state.info.liveable_type && state.info.liveable_type === "EVENT") {
          //   await dispatch('syncLiveEventRank');
          // }
        }

        // Live情報更新
        // await dispatch('syncLiveInfoForTimeUpdate')

        // ギフト系の更新
        let item = JSON.parse(commentOne.payload.extension)
        if( item.RAW ){
          const current_percentage = item.RAW.current_percentage;
          if (state.info.liveable_type === "FUND" && current_percentage) {
            dispatch('setCurrentPercentageInfo',current_percentage)
          }
          const currentPoint = item.RAW.live_point
          commit('SET_LIVE_POINT',currentPoint)
        }
    }
  },
  setCurrentPercentageInfo({ commit }, current_percentage) {
    const temp = current_percentage <= 9999?current_percentage:9999
    const ones = temp % 10
    const tens = Math.floor(temp % 100 / 10 )
    const hundreds = Math.floor(temp % 1000 / 100)
    const thousands = Math.floor(temp % 10000 / 1000)
    const value = {
      current_percentage:current_percentage,
      ones:ones,
      tens:tens,
      hundreds:hundreds,
      thousands:thousands
    }
    commit('SET_CURRENT_PERCENTAGE',value)
  },

  updateLiveAttrInfo({ commit }, liveAttrInfo) {
    commit('UPDATE_LIVE_ATTR_INFO', liveAttrInfo);
  },
  async timSendMessagePlain({ commit, state, rootState }, data) {
    // 切断していたら再接続をする
    await tryReconnect(commit, state,rootState)
    const { text, avatar, message_group, type } = data;
    let message = state.insTIM.createCustomMessage({
      to: state.info.message_group || message_group,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: 'text',
        description: text,
        extension: JSON.stringify({
          NICKNAME: rootState.user.profile_account_name,
          AVATAR: avatar,
          LIVE_ID: state.info.id,
          type,
          ATTRIBUTE:rootState.user.attribute,
          CURRENT_TITLE: rootState.user.profile_profileable_current_title,
          IS_FIRST:state.is_first,
          PROFILE_ID:rootState.user.profile.id,
          PROFILEABLE_TYPE:rootState.user.profile.profileable_type,
          PROFILEABLE_ID:rootState.user.profile.profileable_id,
        }),
      }
    })
    await state.insTIM.sendMessage(message);
  },
  async timSendMessageGift({ commit, state, rootState }, data) {
    // 切断していたら再接続をする
    await tryReconnect(commit, state,rootState)
    const { text, GIFT_ID, RAW, avatar, giftIcon } = data;
    try {
      let message = state.insTIM.createCustomMessage({
        to: state.info.message_group,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          data: 'gift',
          description: text,
          extension: JSON.stringify({
            GIFT_ID,
            NICKNAME: rootState.user.profile_account_name,
            AVATAR: avatar,
            LIVE_ID: state.info.id,
            RAW,
            giftIcon,
            ATTRIBUTE:rootState.user.attribute,
            CURRENT_TITLE: rootState.user.profile_profileable_current_title,
            IS_FIRST:state.is_first,
            PROFILE_ID:rootState.user.profile.id,
            PROFILEABLE_TYPE:rootState.user.profile.profileable_type,
            PROFILEABLE_ID:rootState.user.profile.profileable_id,
          }),
        }
      })
      await state.insTIM.sendMessage(message);
    }catch(error){
      console.log(error)
    }
  },
  async timSendMessageEvent({ commit, state, rootState }, data) {
    // 切断していたら再接続をする
    await tryReconnect(commit, state,rootState)
    const { EVENT_TYPE, RAW, avatar } = data;
    let message = state.insTIM.createCustomMessage({
      to: state.info.message_group,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: 'event',
        description: EVENT_TYPE,
        extension: JSON.stringify({
          NICKNAME: rootState.user.profile_account_name,
          AVATAR: avatar,
          LIVE_ID: state.info.id,
          RAW,
          ATTRIBUTE:rootState.user.attribute,
          CURRENT_TITLE: rootState.user.profile_profileable_current_title,
          IS_FIRST:state.is_first,
          PROFILE_ID:rootState.user.profile.id,
          PROFILEABLE_TYPE:rootState.user.profile.profileable_type,
          PROFILEABLE_ID:rootState.user.profile.profileable_id,
        }),
      }
    })
    await state.insTIM.sendMessage(message);
  },
  async timSendMessagePlainForChat({ commit, state, rootState }, data) {
    // 切断していたら再接続をする
    await tryReconnect(commit, state,rootState)
    const { id, messageGroup, text, type, avatar } = data;
    let message = state.insTIM.createCustomMessage({
      to: messageGroup,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: 'chat',
        description: text,
        extension: JSON.stringify({
          NICKNAME: rootState.user.profile_account_name,
          AVATAR: avatar,
          CHATROOM_ID: id,
          TYPE:type,
          ATTRIBUTE:rootState.user.attribute,
          CURRENT_TITLE: rootState.user.profile_profileable_current_title,
          PROFILE_ID:rootState.user.profile.id,
          PROFILEABLE_TYPE:rootState.user.profile.profileable_type,
          PROFILEABLE_ID:rootState.user.profile.profileable_id,
        }),
      }
    })
    await state.insTIM.sendMessage(message);
  },
}

async function tryReconnect( commit, state, rootState ){
  let is_need_retry = false;
  try {
    await state.insTIM.getMyProfile()
  } catch (error){
    console.log('getMyProfile Error:' + error)
    is_need_retry = true;
  }
  if( !is_need_retry ) { return }

  // 再接続
  commit('SET_INS_TIM', TIM.create({
    SDKAppID: state.SDKAppID,
  }));
  state.insTIM.setLogLevel(6);

  try {
    await state.insTIM.login({
      userID: rootState.user.module + rootState.user.id,
      userSig: state.info.sig
    });
  } catch(error){
    console.log('try tim login Error:' + error)
  }
  try {
    await state.insTIM.joinGroup({
      groupID: state.info.message_group,
      type: TIM.TYPES.GRP_AVCHATROOM
    });
  } catch(error){
    console.log('try tim join Group Error:' + error)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
